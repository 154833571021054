import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import Section from "./Section"
import Phone from "../images/matchchat_phone.png"
// import completePrivacy from "../images/getStarted/complete-privacy.svg"
// import halalFun from "../images/getStarted/halalcouple-sm.svg"
// import contactCouple from "../images/getStarted/contact-couple.svg"
// import videoChat from "../images/getStarted/video-calling-couple.svg"
import completePrivacy from "../images/getStarted/complete-privacy.png"
import halalFun from "../images/getStarted/halalcouple-sm.png"
import contactCouple from "../images/getStarted/contact-couple.png"
import videoChat from "../images/getStarted/video-calling-couple.png"
import clsx from "clsx"
import AnimateRight from "./animations/AnimateRight"
import SlideUpImage from "./animations/slideUpImage"
import AnimateLeft from "./animations/AnimateLeft"

const useStyle = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
  },
  sectionTitle: {
    fontSize: "2rem",
    textTransform: "uppercase",
    alignText: "center",
    fontWeight: "600",
    margin: 0,
    [theme.breakpoints.down("lg")]: {
      fontSize: "2rem",
    },
  },

  featuers: {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingBottom:"40px",
      paddingTop:"20px"
    },
    "& img": {
      width: "80px",
      height: "auto",
    },
  },
  featuer: {
    marginBottom: "40px",
    minHeight: "200px",
  },
  phoneImage: {
    order: 0,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    padding:'20px',
    "& img":{
      [theme.breakpoints.down('md')]:{
        maxWidth: "200px !important",
      }
    }
  },
}))

const MatchChatDate = () => {
  const classes = useStyle()
  return (
    <Section id={`matchChatDate`}>
      <Grid
        item
        container
        justify={"center"}
        direction="row"
        className={classes.root}
      >
        <Grid
          item
          container
          direction="column"
          lg={4}
          md={4}
          sm={12}
          className={classes.featuers}
        >
          <Grid item className={classes.featuer}>
            <AnimateRight>
              <span>
                <img src={halalFun} alt={"mjfn"} />
              </span>
              <Typography component="h4" className="purple">
                Halal, free, and fun
              </Typography>
              <br />
              <Typography
                variant="body1"
                component="p"
              >
                Be content with your amount of 'Dunia' but never with you amount
                of 'Deen'. Never miss any communication
              </Typography>
            </AnimateRight>
          </Grid>
          <Grid item>
            <AnimateRight>
              <span>
                <img src={contactCouple} alt={"mjfn"} />
              </span>
              <Typography  component="h4" className="purple">
                Get notified
              </Typography>
              <br />
              <Typography variant="body1" component="p">
                Never miss any communication with instant notification. Never
                miss any communication with instant notification.
              </Typography>
            </AnimateRight>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.phoneImage)}
          item
          lg={4}
          xl={4}
          md={4}
          sm={12}
        >
            <SlideUpImage src={Phone} alt="phone" />
        </Grid>
        <Grid
          item
          container
          direction="column"
          lg={4}
          md={4}
          sm={12}
          className={classes.featuers}
        >
          <Grid item className={classes.featuer}>
            <AnimateLeft>
              <span>
                <img src={completePrivacy} alt={"mjfn"} />
              </span>
              <Typography  component="h4" className="purple">
                Your privacy is our priority
              </Typography>
              <br />
              <Typography variant="body1" component="p">
                Protect our member's privacy and keep it confidential. Never
                miss any communication with instant notification.
              </Typography>
            </AnimateLeft>
          </Grid>

          <Grid item>
            <AnimateLeft>
              <span>
                <img src={videoChat} alt={"mjfn"} />
              </span>
              <Typography   component="h4" className="purple">
                Free Video Calling
              </Typography>
              <br />
              <Typography variant="body1" component="p">
                Get to know your match even better with FREE Video Calling.
                Never miss any communication with instant notification.
              </Typography>
            </AnimateLeft>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  )
}

export default MatchChatDate
