import React from 'react'
import { useInView } from "react-intersection-observer"

const SlideUpImage = ({ threshold = 0.0, alt, ...imgProps}) => {
  const [ref, inView] = useInView({ threshold })

  return (
    <img
      style={{
        transition: "opacity 1000ms, transform 1500ms",
        opacity: inView ? 1 : 0,
        transform: `translateY(${inView ? 0 : 20}px)`,
        width: 'inherit',
        height: 'inherit',
      }}
      ref={ref}
      alt={alt}
      {...imgProps}
    />
  )
}

export default SlideUpImage