import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import { Link } from "gatsby"
import React from "react"
import img from "../images/gold.png"
import AnimateLeft from "./animations/AnimateLeft"
import AnimateRight from "./animations/AnimateRight"

import Section from "./Section"
const useStyles = makeStyles(theme => ({
  root: {
    paddingBlock: "80px"
  },
}))

export default function RajaaRaniGold() {
  const classes = useStyles()
  return (
    <Section id={`RajaaRaniGold`}>
      <Grid
        container
        justify="center"
        direction="row"
        alignItems="center"
        className={classes.root}
      >
        <Grid item md={6} sm={12} xs={12}>
          <AnimateRight>
            <img src={img} alt="mkjfs" />
          </AnimateRight>
        </Grid>
        <Grid item md={6} className="text-left-mb-center">
          <AnimateLeft>
            <Typography
               
              component="h1"
              style={{ color: "#E9A939" }}
            >
              RajaaRani Gold
            </Typography>
            <br />
            <Typography component="p">
              Get married quicker with our Gold plan. Our Gold subscription
              unlocks features according to your preferences and make you find a
              profile faster. The user can add a 15 sec introductory video to
              their profile so it would be easier to trust and start a chat
              without hassle.
            </Typography>
            <Link to="/gold">
            <Button
              style={{
                backgroundColor: "#E9A939",
                color: "#fff",
                padding: "10px 20px",
                marginTop: "20px",
                borderRadius: "30px",
              }}

            >
              Learn More
            </Button>
            </Link>
          </AnimateLeft>
        </Grid>
      </Grid>
    </Section>
  )
}
