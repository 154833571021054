import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Section from './Section'
import  one from '../images/3.jpg'
import  icon from '../images/icon-party.png'
import clsx from 'clsx'

const useStyle = makeStyles((theme)=>({
    root:{
        width: '100% !important', 
     

      
    },
    title:{
        marginBottom: '80px',
        [theme.breakpoints.down('sm')]:{
            paddingTop:'60px',
            paddingBottom:'60px' ,
            textAlign: 'center',
            marginBottom: '40px'
        },
    },
    icon:{
        height :'40px',
        width: '40px'
    },
    box:{
        display: 'grid',
        placeContent: 'center',
        padding: '10px', 
        maxHeight: '150px',
        borderRadius: '10px',
        placeItems: 'center',
        width: '100%',
        '& img ':{
            margin: 0,
            height :'150px !important',
            borderRadius: '10px',
        },
        [theme.breakpoints.down('sm')]:{
            marginBottom: '40px',
            padding: '10px',
        },
    },
        success:{
            display: 'grid',
            placeContent: 'center',
            padding: '10px', 
            maxHeight: '150px',
            borderRadius: '10px',
            placeItems: 'center',
            width: '100%',
            marginTop: '40px',
            [theme.breakpoints.down('sm')]:{
                marginBottom: '40px',
                padding: '10px',
            },

    },
    grow:{
        'transition': 'all 0.2s ease-in-out',
        // animation: '$grow .25s',
        // transformOrigin: 'center'
        '& :hover':{
            transform: 'scale(1.1)'
        }
        
    },

    '@keyframes grow':{
        'from' :{
            transform: 'scale(0)'
          },
        "to": { transform: 'scale(1.2)' }
      }

    
}))


const data = [{src:one, alt:'kmflkf'},{src:one, alt:'kmflkf'}, {src:one, alt:'kmflkf'},{src:one, alt:'kmflkf'},{src:one, alt:'kmflkf'},{src:one, alt:'kmflkf'}]


export default function Stories() {
    const classes = useStyle();
   // const [hover, sethover] = useState(false);

    return (
       <Section colorBG={'#EED2EF'}>
               <Typography className={classes.title}
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-delay="200"
            data-sal-easing="ease"
           >
           Success stories from our community
           </Typography>
           <Grid container direction='row' className={classes.root } >
       
            {
                data.map((img)=>(
                    <Grid item md={2} xs={6} className={clsx(classes.box,classes.grow)}   
                    data-sal="slide-up"
                    data-sal-duration="1000"
                    data-sal-delay="300"
                    data-sal-easing="ease"
                    
                    key={img}>
                        <img src={img.src} alt={img.alt} />
                </Grid>
                ))
            }  
           </Grid>
           <Grid container className={classes.success}
               data-sal="slide-up"
               data-sal-duration="1000"
               data-sal-delay="200"
               data-sal-easing="ease"
           >
                  <img className={classes.icon} src={icon} alt={'mvdkjm'}/>
                  <br/>
                    <Typography>60,000</Typography>
                    <br/>
                    <Typography>Success stories</Typography>
                    <br/>
                    <Button style={{backgroundColor:'#E9A939', color: '#fff', padding:'10px 20px', marginTop:'20px', borderRadius:'30px'}}> View More</Button>
                </Grid>
       </Section>
    )
}
