import React from // { useRef, useState, useEffect }
"react"
import { Grid, Typography } from "@material-ui/core"
import Image1 from "../images/features/3.png"
import Image2 from "../images/features/2.png"
import Image3 from "../images/features/1.png"
import AnimateRight from "./animations/AnimateRight"
import AnimateLeft from "./animations/AnimateLeft"
import AnimateRotateRight from './animations/AnimateRotateRight'
import AnimateRotateLeft from "./animations/AnimateRotateLeft"

const Feature = () => {

  return (
    <div>
      <Grid container direction="row" justify="center">
        <Grid
          container
          item
          md={7}
          sm={12}
          justify="center"
          alignItems="center"
        >
          <AnimateRight>
          <Grid item>
            <Typography
                   
              component="h2"
              className="heading text-left-mb-center purple"
            >
              Strict profile screening
            </Typography>
            <Typography component="p" className="paragraph text-left-mb-center">
              Our monitoring team is committed to ensure that every profile put
              up on RajaRani is screened to ensure you continue to have a smooth
              partner search experience.
            </Typography>
          </Grid>
          </AnimateRight>
        </Grid>
        <Grid item md={5} sm={12} className="order-one">
          <AnimateRotateLeft
          src={Image1}
          alt="of a phone"
            className="image-container"
          />
        </Grid>
      </Grid>

      <Grid container direction="row" justify="center">
        <Grid item md={5} sm={12}>
          <AnimateRotateRight
            id="image2"
            src={Image2}
            alt=" of a phone"
            className="image-container"
          />
        </Grid>
        <Grid
          container
          item
          md={7}
          sm={12}
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <AnimateLeft>
            <Typography
                   
              component="h2"
              className="heading text-left-mb-center gold"
            >
              Your privacy is our priority
            </Typography>
            <Typography component="p" className="paragraph text-left-mb-center">
              Being a perfect combination of traditional and religious
              matchmaking technology, we provide ultimate privacy. This means we
              will never disclose your information (i.e. personal details,
              contact details) to the other party without your consent so you
              can browse our database of proposals with full confidence and
              self-esteem.
            </Typography>
            </AnimateLeft>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center">
        <Grid
          container
          item
          md={7}
          sm={12}
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <AnimateRight>
            <Typography
                   
              component="h2"
              className="heading text-left-mb-center purple"
            >
              Free Video Calling
            </Typography>
            <Typography component="p" className="paragraph text-left-mb-center">
              Get to know your match even better with FREE Video Calling. You
              decide whom you can call and you never have to share your phone
              number initially.
            </Typography>
            </AnimateRight>
          </Grid>
        </Grid>
        <Grid item md={5} sm={12} className="order-one">
          <AnimateRotateLeft
            id="image3"
            src={Image3}
            alt=" of a phone"
            className="image-container"
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center">
        <Grid item md={5} sm={12}>
          <AnimateRotateRight
            id="image"
            src={Image2}
            alt=" of a phone"
            className="image-container"
          />
        </Grid>
        <Grid
          container
          item
          md={7}
          sm={12}
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <AnimateLeft>
            <Typography
                   
              component="h2"
              className="heading text-left-mb-center gold"
            >
              Get notified
            </Typography>
            <Typography component="p" className="paragraph text-left-mb-center">
              Never miss any communication with instant notification . Get
              notified promptly with our regular alerts!
            </Typography>
            </AnimateLeft>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Feature
