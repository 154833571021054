import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import Section from "./Section"
import Phone from "../images/phone-filters-US@2x.png"
import Feature from "./feature"
import clsx from "clsx"
import AnimateRight from "./animations/AnimateRight"
import AnimateLeft from "./animations/AnimateLeft"

const useStyle = makeStyles(theme => ({
  text: {
    textAlign: "left",
    paddingTop: "100px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  image: {
    textAlign: "center",
    "& img": {
      width: "200px",
      boxShadow: "0 0 5px  0px rgba(0,0,0,0.2)",
      borderRadius: "25px",
    },
  },
  header: {
    fontSize: "3rem",
  },
  button: {
    marginTop: "30px",
    backgroundColor: "#E9A939",
    color: "#fff",
    paddingInline: "60px",
    padding: "15px 60px",
    borderRadius: "25px",
  },
}))

export default function Features() {
  const classes = useStyle()

  return (
    <div>
      <Section colorBG="#F6C7EF">
        <Grid container direction="row">
          <Grid
            item
            md={6}
            lg={6}
            sm={12}
            xs={12}
            className={clsx(classes.image, "image-container")}
          >
            <AnimateRight>
              <img src={Phone} alt="phone" />
            </AnimateRight>
          </Grid>
          <Grid item md={6} lg={6} className={classes.text}>
            <AnimateLeft>
              <Typography component="h2" className={classes.header}>
                Modern Search Technology
              </Typography>
              <br />
              <Typography component="p">
                Our customization, filtering, and blocking systems strive to
                build technology that will only bring you matches that are
                related to your search.
              </Typography>
              <Button
                style={{
                  backgroundColor: "#E9A939",
                  color: "#fff",
                  padding: "10px 20px",
                  marginTop: "20px",
                  borderRadius: "30px",
                }}
              >
                TRY FOR FREE
              </Button>
            </AnimateLeft>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Feature />
      </Section>
    </div>
  )
}
