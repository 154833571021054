import React from "react"
import About from "../components/about.jsx"
import Features from "../components/features.jsx"
import Hero from "../components/hero.jsx"
import HowItWorks from "../components/howItWorks.jsx"

import Layout from "../components/layout"
import MatchChatDate from "../components/matchChatDate.jsx"
// import Newsletter from "../components/newsletter.jsx"
import RajaaRaniGold from "../components/rajaaraniGold.jsx"
import SEO from "../components/seo"
import Stats from "../components/stats.jsx"
import Stories from "../components/stories.jsx"

const IndexPage = () => (
  <Layout>
    <SEO title="Rajaarani - Muslim Matrimony Site" />
    <Hero
      heading="Where Muslims find a way!"
      subHeading="RajaRani par ao, phupo ki beti bhool jao!"
      hideButtons={false}
      scrollLink="#matchChatDate"
    />
    <MatchChatDate />
    <HowItWorks />
    <Features />

    <Stats />
    <Stories />

    <RajaaRaniGold />
    <About />
    {/* <Newsletter/> */}
  </Layout>
)

export default IndexPage
