import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import World from "../images/stats/glob.png"
import Users from "../images/stats/membericon.png"
import Party from "../images/stats/party.png"
import SlideUp from "./animations/slideUp"
const useStyle = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "center",
    display: "flex",
    paddingTop: "80px",
    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },

  box: {
    height: "120px",
    width: "32%",
    color: "rgba(52,16,52,1)",
    padding: "20px",
    boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.2)",
    borderRadius: "15px",
    textAlign: "center",
    margin: "0px 1%",
    display: "grid",
    "& h2": {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "40px 0px !important",
      width: "100%",
    },
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  boxIcon: {
    margin: "0",
  },
}))

const data = [
  {
    title: "Members",
    text: "4500000",
    image: Users,
    key:1,
  },
  {
    title: "Countries",
    text: "45",
    image: World,
    key:2,
  },
  {
    title: "Weddings",
    text: "500000",
    image: Party,
    key:3,
  },
]
export default function Stats() {
  const classes = useStyle()
  return (
    <div className={classes.root}>
      <div className={clsx(classes.flex, "wrapper-fluid")}>
        <SlideUp>
          {data.map(box => (
            <div key={box.key} className={classes.box}>
              <div className={classes.row}>
                <div className={classes.boxIcon}>
                  <img src={box.image} alt={box.title} />
                </div>
                <div>
                  <Typography component="h5" >
                    {box.title}
                  </Typography>

                  <Typography component="p" variant="body1">
                    {box.text}
                  </Typography>
                </div>
              </div>
            </div>
          ))}
          </SlideUp>
      </div>
    </div>
  )
}
