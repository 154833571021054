import { Grid, Typography, makeStyles } from "@material-ui/core"
import React from "react"
import BG from "../images/howItWorks/bg.png"
import BG2 from "../images/howItWorks/bg2.png"
import Phone from "../images/howItWorks/_phone.png"
import clsx from "clsx"
import SlideUp from "./animations/slideUp"
import AnimateRight from "./animations/AnimateRight"
import AnimateLeft from "./animations/AnimateLeft"
import AnimateMoveDown from "./animations/animateMoveDown"
import AnimateMoveUp from "./animations/animateMoveUp"
import AnimateRotateRight from "./animations/AnimateRotateRight"
import AnimateRotateLeft from "./animations/AnimateRotateLeft"
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const useStyle = makeStyles(theme => ({
  root: {
    backgroundColor: "#fff",
    overflow: "hidden",
    "& h2": {
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: "20px",
      paddingLeft: "20px",
    },
  },
  wrapper: {
    maxWidth: "1200px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  heading: {
    textAlign: "center",
  },
  image: {
    width: "40%",
    position: "absolute",
    zIndex: "0",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      left: "auto !important",
      right: "auto !important",
    },
  },
  left: {
    left: "0",
  },
  right: {
    right: "0",
  },
  sectionOne: {
    zIndex: "9999",
    position: "relative",
  },
  title: {
    fontSize: "30px !important",
    textTransform: "uppercase",
    color: "#91009E",
  },
  subTitle: {
    "& h2": {
      fontWeight: "300",
      fontSize: "1.5rem",
    },
  },
  text: {
    fontWeight: "500",
    lineHeight: "120%",
    marginBottom: "40px",
  },
  steps: {
    margin: "40px 0px",
  },
  phoneImg: {
    position: "absolute",
    left: "80%",
    transform: "rotate(-10deg)",
    top: "0%",
    right: "auto",
    bottom: "auto",
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      right: "10%",
      top: "10%",
      left: "auto",
      bottom: "auto",
    },
    [theme.breakpoints.down("md")]: {
      width: "150px",
    },
  },
  phoneImg2: {
    position: "absolute",
    left: "40%",
    transform: "rotate(-10deg)",
    top: "30%",
    right: "auto",
    bottom: "auto",
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      right: "auto",
      top: "auto",
      left: "10%",
      bottom: "0",
    },
    [theme.breakpoints.down("md")]: {
      width: "150px",
    },
  },
  phoneImg3: {
    position: "absolute",
    right: "80%",
    top: "0%",
    left: "auto",
    bottom: "auto",
    width: "200px",
    transform: "rotate(10deg)",
    [theme.breakpoints.down("sm")]: {
      right: "10%",
      top: "0",
      left: "auto",
      bottom: "auto",
    },
    [theme.breakpoints.down("md")]: {
      width: "150px",
    },
  },
  phoneImg4: {
    position: "absolute",
    right: "40%",
    top: "30%",
    left: "auto",
    bottom: "auto",
    width: "200px",
    transform: "rotate(10deg)",
    [theme.breakpoints.down("sm")]: {
      right: "auto",
      top: "auto",
      left: "10%",
      bottom: "0",
    },
    [theme.breakpoints.down("md")]: {
      width: "150px",
    },
  },
  textColor: {
    color: "#91009E",
  },
  centerPhone: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      order: -1,
      minHeight: "40vh",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "60vh",
      "& img": {
        width: "100px !important",
      },
    },
  },
}))

const data = {
  title: "how it works",
  subTitle:
    "It's simple, the moment you login, search, mention your interest, the closer you get to your life partner. Here are few tips of how you can use RajaRani.com more effectively.",
  steps: [
    {
      stepTitle: "Register & finish your profile",
      text:
        "You need to make sure your profile is complete after the registration process. You can then begin by uploading photos and verifing your phone number to increase chances of getting desired responses.",
        icon: <AccountCircleIcon/>
      },
    {
      stepTitle: "Search for competent Matches",
      text:
        "You need to make sure your profile is complete after the registration process. You can then begin by uploading photos and verifing your phone number to increase chances of getting desired responses.",
      icon:<SearchIcon/>
      },
    {
      stepTitle: "Fill your Partner Preferences",
      text:
        "You need to make sure your profile is complete after the registration process. You can then begin by uploading photos and verifing your phone number to increase chances of getting desired responses.",
      icon:<AssignmentIcon/>
      },
  ],
  steps2: [
    {
      stepTitle: "Show your Interest",
      text:
        "You need to make sure your profile is complete after the registration process. You can then begin by uploading photos and verifing your phone number to increase chances of getting desired responses.",
    icon: <FavoriteBorderIcon/>
      },
    {
      stepTitle: "Choose Filters",
      text:
        "You need to make sure your profile is complete after the registration process. You can then begin by uploading photos and verifing your phone number to increase chances of getting desired responses.",
   icon:<FilterListIcon/>
      },
  ],
}

export default function HowItWorks() {
  const classes = useStyle()
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Grid item md={12} className={classes.heading}>
          <div>
            <SlideUp>
              <Typography
                component="h2"
                className={clsx(classes.title, "heading")}
              >
                {data.title}
              </Typography>
            </SlideUp>
          </div>
          <div className={clsx(classes.subTitle, "paragraph")}>
            <SlideUp>
              <Typography component="p">{data.subTitle}</Typography>
            </SlideUp>
          </div>
        </Grid>
        <Grid container>
          <AnimateRotateLeft
            src={BG}
            alt="mkmds"
            className={clsx(classes.image, classes.right)}
          />
          <Grid
            item
            container
            md={12}
            direction="row"
            className={classes.sectionOne}
          >
            <Grid
              item
              container
              md={6}
              sm={12}
              direction="column"
              justify="space-evenly"
              style={{ minHeight: "100vh" }}
              className="text-left-mb-center"
            >
              {data.steps.map(step => (
                <Grid item key={step.stepTitle}>
                  <AnimateRight>
                    <Typography component="h2" className="heading purple">
                    {step.icon}
                      <br/>
                      {step.stepTitle}
                    </Typography>

                    <Typography
                      component="p"
                      className={clsx(classes.text, "paragraph")}
                    >
                      {step.text}
                    </Typography>
                  </AnimateRight>
                </Grid>
              ))}
            </Grid>
            <Grid item container md={6} sm={12} className={classes.centerPhone}>
              <Grid item md={6} sm={6}>
                <AnimateMoveDown
                  src={Phone}
                  rotate={-10}
                  alt="phone"
                  className={classes.phoneImg}
                />
              </Grid>
              <Grid item md={6} sm={6}>
                <AnimateMoveUp
                  src={Phone}
                  rotate={-10}
                  alt="phone"
                  className={classes.phoneImg2}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <AnimateRotateRight
            src={BG2}
            alt="mkmds"
            className={clsx(classes.image, classes.left)}
          />
          <Grid
            item
            container
            md={12}
            direction="row"
            className={classes.sectionOne}
          >
            <Grid item container md={6} sm={6} className={classes.centerPhone}>
              <Grid item md={6}>
                <AnimateMoveDown
                  src={Phone}
                  rotate={10}
                  alt="phone"
                  className={classes.phoneImg3}
                />
              </Grid>
              <Grid item md={6}>
                <AnimateMoveUp
                  src={Phone}
                  alt="phone"
                  className={classes.phoneImg4}
                  rotate={10}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              md={6}
              direction="column"
              justify="space-evenly"
              className="text-left-mb-center howItworks-text"
            >
              {data.steps2.map(step => (
                <Grid item key={step.stepTitle}>
                  <AnimateLeft>
                    <Typography component="h2" className="heading purple">
                      {step.icon}
                      <br/>
                      {step.stepTitle}
                    </Typography>

                    <Typography
                      component="p"
                      className={clsx(classes.text, "paragraph")}
                    >
                      {step.text}
                    </Typography>
                  </AnimateLeft>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
